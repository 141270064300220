<script>
  import {Drawer, Modal} from 'ant-design-vue';
  import {ScreenWidthMd} from '@/config/config';

  export default {
    data() {
      return {
        show: false
      }
    },

    render() {
      const ScreenWidth = window.innerWidth;
      const on = {
        ...this.$listeners,
      }

      const props = {...this.$attrs, ...this.$props};
      const type = this.type;
      const className = `sp-dialog ${type ? `dialog-${type}` : 'dialog-default'}`;
      const scopedSlots = {...this.$scopedSlots}

      delete props.width;
      delete props.height;
      delete on.close;

      if (ScreenWidth < ScreenWidthMd) {
        delete props.bodyStyle;

        return <a-drawer
          visible={this.show}
          class={className}
          width={this.mWidth}
          height={this.mHeight}
          props={props}
          scopedSlots={scopedSlots}
          on={on}
          onClose={this.onClose}
        >
          {this.$slots.default ? <div class='ant-drawer-body-inner'>{this.$slots.default}</div> : ''}
          {this.$slots.footer ? <div class='ant-drawer-footer-inner'>{this.$slots.footer}</div> : ''}
        </a-drawer>
      }
      else {
        return <a-modal
          visible={this.show}
          class={className}
          width={this.pcWidth}
          height={this.pcHeight}
          props={props}
          scopedSlots={scopedSlots}
          on={on}
          onCancel={this.onClose}
        ></a-modal>

        // return h('AModal', {
        //   class: className,
        //   props: {
        //     width: this.pcWidth,
        //     height: this.pcHeight,
        //     visible: this.show,
        //     ...props
        //   },
        //   scopedSlots: {
        //     ...this.$scopedSlots
        //   },
        //   on: {
        //     ...on,
        //     cancel: this.onClose
        //   },
        // })
      }
    },

    props: {
      visible: Boolean,
      type: String, // success primary sub

      mWidth: Drawer.props.width,
      mHeight: Object.assign(Drawer.props.height, {default: '80%'}),
      placement: Object.assign(Drawer.props.placement, {default: 'bottom'}),

      pcWidth: Object.assign(Modal.props.width, {default: 620}),
      pcHeight: Modal.props.height,
    },

    watch: {
      visible: {
        handler(newVal) {
          this.show = newVal;
        },
        immediate: true
      }
    },

    components: {
      ADrawer: Drawer,
      AModal: Modal
    },
    methods: {
      onClose() {
        this.$emit('update:visible', false);
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/scss/dialog.scss";
</style>
